













import { Component, Vue } from 'vue-property-decorator';
import SidebarMenu from './SidebarMenu.vue';
import { dashboardRoutes } from '@/router';
import { RouteConfig } from 'vue-router/types/router';

@Component({
  components: {
    SidebarMenu,
  },
})
export default class Sidebar extends Vue {
  public menu: RouteConfig[] = [];

  created(): void {
    dashboardRoutes.forEach((route: RouteConfig) => {
      if (route.meta.sidebar) {
        this.menu.push(route);
      }
    });
  }
}




























import { Component, Vue } from 'vue-property-decorator';
import { Saque } from '@/plugins/api';
import FileSaver from 'file-saver';
import contentDisposition from 'content-disposition';
import html2pdf from 'html2pdf-fix-jspdf';

@Component
export default class SaquesExportar extends Vue {
  get filtros(): IFiltroValues[] {
    return this.$store.getters.filtrosSelecionados;
  }

  public getFilename(disposition: string): string {
    const disp = contentDisposition.parse(disposition);
    return disp.parameters.filename;
  }

  public exportarCSV(): void {
    const request = {
      params: {
        format: 'csv',
        sortBy: 'usuarios.id',
        sortDesc: 'ASC',
        filters: this.filtros,
      },
    };
    Saque.exportar(request)
      .then((response) => {
        const type = response.headers['content-type'];
        const filename = this.getFilename(
          response.headers['content-disposition']
        );
        const blob = new Blob([response.data], { type: type });
        FileSaver.saveAs(blob, filename);

        this.$swal({
          icon: 'success',
          title: 'Baixando Relatório',
        });
      })
      .catch(() => {
        this.$swal({
          icon: 'error',
          title: 'Erro',
        });
      });
  }

  public exportarPDF(tipo = 'pdf'): void {
    const request = {
      params: {
        format: tipo,
        sortBy: 'usuarios.nome',
        sortDesc: 'ASC',
        filters: this.filtros,
      },
    };
    Saque.exportar(request)
      .then((response) => {
        const body = response.data;
        const filename = this.getFilename(
          response.headers['content-disposition']
        );

        const opt = {
          filename: filename,
          margin: 1,
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'cm', format: 'a4', orientation: 'landscape' },
          pagebreak: {
            mode: 'avoid-all',
            avoid: 'table',
          },
        };

        const worker = html2pdf(undefined, opt);
        worker.from(body).save();

        this.$swal({
          icon: 'success',
          title: 'Baixando Relatório',
        });
      })
      .catch(() => {
        this.$swal({
          icon: 'error',
          title: 'Erro',
        });
      });
  }
}

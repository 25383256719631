











































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import { Saque, Auth } from '@/plugins/api';
import PageTitle from '@/layouts/components/PageTitle.vue';
import env from '@/env';

@Component({
  components: {
    PageTitle,
  },
})
export default class Topbar extends Vue {
  public interval: NodeJS.Timeout | null = null;
  public limiteSaques = 5;
  public saques = [] as ISaque[];
  public quantidade = 0;
  public verticalCollapsed = false;

  get sidebarState(): boolean {
    return this.$store.state.sidebar.open;
  }

  created(): void {
    const verticalCollapsed = localStorage.getItem('vertical-collapsed');
    const isClosed = JSON.parse(verticalCollapsed ?? 'false');
    this.$store.commit('SET_SIDEBAR_OPEN', !isClosed);
    this.updateSidebar(!isClosed);
  }

  mounted(): void {
    this.updateNotification();
  }

  toogleSidebar(): void {
    this.$store.commit('SET_SIDEBAR_OPEN', !this.sidebarState);
  }

  @Watch('sidebarState')
  updateSidebar(isOpen: boolean): void {
    const body = document.body;

    if (isOpen) {
      body.classList.add('sidebar-enabled');
      body.classList.remove('vertical-collapsed');
    } else {
      body.classList.remove('sidebar-enabled');
      body.classList.add('vertical-collapsed');
    }

    localStorage.setItem('vertical-collapsed', (!isOpen).toString());
  }

  updateNotification(): void {
    if (Auth.instance.isLogged) {
      const request = {
        params: {
          sortBy: 'data_solicitacao',
          sortDesc: 'DESC',
          size: this.limiteSaques,
          filters: [
            {
              field: 'saques.situacao',
              operator: 'eq',
              value: 'pendente',
            },
          ],
        },
      };
      Saque.get(request).then((response: GetSaquesResponse) => {
        this.saques = response.data;
        this.quantidade = response.metadata.total;
        if (this.$store.state.sidebar['/saques'] !== this.quantidade) {
          this.$store.commit('SET_SIDEBAR_BADGE', {
            id: '/saques',
            value: response.metadata.total,
          });
        }
      });
    }
  }

  getImagePerfil(url: string|undefined): string {
    if (url === undefined) {
      url = '/painel/images/perfil/sem-imagem.svg';
    }
    return env.VUE_APP_URL_DASHBOARD + url;
  }

  logout(): void {
    Auth.instance.logout();
    this.$router.push('/login?loggedOut');
  }
}

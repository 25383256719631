


















import { Component, Vue, Prop } from 'vue-property-decorator';
import env from '@/env';

@Component
export default class IndicadorCell extends Vue {
  @Prop() public readonly data!: IIndicador;
  @Prop({ default: '' }) public readonly tipo!: string;
  @Prop({ default: true }) public readonly showId!: boolean;

  get url(): string {
    return `${env.VUE_APP_URL_DASHBOARD}painel/indicador/perfil.php?id=${this.data.id}`;
  }

  get nome(): string {
    return this.data.nome + ' ' + this.data.sobrenome;
  }
}











import { Component, Vue, Prop } from 'vue-property-decorator';
import MoskitIcon from '@/assets/images/moskit-icon.svg';

@Component
export default class ContextMoskit extends Vue {
  @Prop() public readonly moskit!: number;

  public get moskitIcon(): string {
    return MoskitIcon;
  }
}





















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class FiltroDate extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public value: IPeriodo | null = null;
  public contem = true;

  get radioID(): string {
    return `contem-${this.data.id}`;
  }

  @Watch('value')
  input(value: IPeriodo|null): void {
    if (value !== null) {
      const filtro: IFiltroValues = {
        field: this.data.id,
        operator: 'rng',
        value: [
          moment(value.start.getTime()).set({hour:0,minute:0,second:0,millisecond:0}).unix(),
          moment(value.end.getTime()).set({hour:23,minute:59,second:59,millisecond:0}).unix(),
        ],
      };
      this.$emit('input', filtro);
    }
  }

  @Watch('contem')
  onContemChange(contem: boolean): void {
    if (!contem) {
      const filtro: IFiltroValues = {
        field: this.data.id,
        operator: 'null',
        value: 'null',
      };
      this.$emit('input', filtro);
    }
  }
}











import { Component, Vue, Prop } from 'vue-property-decorator';
import env from '@/env';

@Component
export default class ContextIndicador extends Vue {
  @Prop() public readonly indicador!: string;

  public urlParceiros = `${env.VUE_APP_URL_DASHBOARD}painel/indicador/perfil.php?id=`;
}

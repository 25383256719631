import { render, staticRenderFns } from "./FormatDate.vue?vue&type=template&id=42502af6&"
import script from "./FormatDate.vue?vue&type=script&lang=ts&"
export * from "./FormatDate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports













import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SidebarMenuItem from './SidebarMenuItem.vue';

@Component({
  components: {
    SidebarMenuItem,
  },
})
export default class SidebarMenu extends Vue {
  @Prop({ default: 'Menu' }) private title!: string;
  @Prop({ default: [] }) private items!: RouteConfig[];
  private activeRoute = '';

  created(): void {
    this.$router.afterEach((to) => {
      this.activeRoute = to.path;
    });
  }
}

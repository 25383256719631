import Api from './Api';
import {AxiosResponse} from "~axios";

export default class Indicador {
  public static async get(request?: IRequestOptions): Promise<GetIndicadoresResponse> {
    const response = await Api.instance.get<GetIndicadoresResponse>('/indicadores', {
      params: request?.params,
    });
    return response.data;
  }

  public static async getByID(id: number): Promise<GetIndicadorResponse> {
    const response = await Api.instance.get<GetIndicadorResponse>(`/indicadores/${id}`);
    return response.data;
  }

  public static async getBanco(id: number): Promise<GetDadosBancariosResponse> {
    const response = await Api.instance.get<GetDadosBancariosResponse>(`/indicadores/${id}/bancos`);
    return response.data;
  }

  public static async updateBanco(
    id: number,
    data: PatchIndicadorRequest
  ): Promise<GenericResponse> {
    const response = await Api.instance.put<GenericResponse>(`/indicadores/${id}/bancos`, data);
    return response.data;
  }

  public static async patchBanco(
    id: number,
    data: PatchIndicadorRequest
  ): Promise<GenericResponse> {
    const response = await Api.instance.patch<GenericResponse>(`/indicadores/${id}/bancos`, data);
    return response.data;
  }

  public static async getComissoes(
    id: number,
    request?: IRequestOptions
  ): Promise<GetComissoesResponse> {
    const response = await Api.instance.get<GetComissoesResponse>(`/indicadores/${id}/comissoes`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async getSaques(id: number, request?: IRequestOptions): Promise<GetSaquesResponse> {
    const response = await Api.instance.get<GetSaquesResponse>(`/indicadores/${id}/saques`, {
      params: request?.params,
    });
    return response.data;
  }

  public static async criarSaque(id: number): Promise<GenericResponse> {
    const response = await Api.instance.post<GenericResponse>(`/indicadores/${id}/saques`);
    return response.data;
  }

  public static async getComprovantes(id: number): Promise<GetComprovantesResponse> {
    const response = await Api.instance.get<GetComprovantesResponse>(
      `/indicadores/${id}/comprovantes`
    );
    return response.data;
  }

  public static async exportar(request?: IRequestOptions): Promise<AxiosResponse<string>> {
    return await Api.instance.get<string>(`/indicadores`, {params: request?.params});
  }

  public static async patchValidacaoTelefone(
    id: number,
    data: PatchValidacaoTelefoneRequest
  ): Promise<GenericResponse> {
    const response = await Api.instance.patch<GenericResponse>(`/indicadores/${id}/validacao`, data);
    return response.data;
  }
}


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ApexOptions} from "apexcharts";
import ApexPt from 'apexcharts/dist/locales/pt-br.json';

interface TooltipCustomData {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: {
    globals: {
      seriesX: number[][];
      colors: string[];
      seriesNames: string[];
    }
  }
}

@Component
export default class GraficoBoleto extends Vue {
  @Prop() public readonly data!: FaturaData;
  @Prop() public readonly interval!: 'month' | 'day';

  get tooltipFormat(): string {
    switch (this.interval) {
      case 'month': return 'MMMM [de] YYYY';
      default: return 'dddd, DD [de] MMMM [de] YYYY';
    }
  }

  get axisFormat(): string {
    switch (this.interval) {
      case 'month': return 'MMM/YY';
      default: return 'DD/MMM';
    }
  }

  get options(): ApexOptions {
    return {
      chart: {
        locales: [ApexPt],
        defaultLocale: 'pt-br',
        fontFamily: '',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          opacityFrom: .65,
          opacityTo: 0,
        }
      },
      grid: {
        show: false,
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
      },
      legend: {
        position: "top",
        floating: true,
        offsetY: 0,
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      markers: {
        strokeWidth: 4,
        strokeOpacity: 1,
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w: { globals: {seriesX, colors} }}: TooltipCustomData) => {
          const hoverAxis = seriesX[seriesIndex][dataPointIndex];
          const hoverIndexes = seriesX.map((seriesX: number[]) => {
            return seriesX.findIndex((series: number) => series === hoverAxis);
          });

          const label = this.$date(hoverAxis).format(this.tooltipFormat);
          let total = 0;

          let hoverList = `<h3 class="popover-header text-center">${label}</h3>`;

          hoverIndexes.forEach((hoverIndex: number, seriesEachIndex: number) => {
            if (hoverIndex >= 0) {
              const value: number = series[seriesEachIndex][hoverIndex];
              total += value;
              hoverList += `<div class="d-flex apexcharts-tooltip-series-group py-1">
                              <span class="apexcharts-tooltip-marker" style="background: ${colors[seriesEachIndex]};"></span>
                              <span>${value}</span>
                            </div>`;
            }
          });
          hoverList += `<div class="d-flex border-top p-2 justify-content-center">
                          <span class="font-weight-bold">${total}</span>
                        </div>`;

          return hoverList;
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            month: 'MMM/yy',
            day: 'dd/MMM',
          },
          style: {
            colors: 'rgba(0,0,0,.4)',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        floating: true,
        labels: {
          offsetX: 29,
          align: 'left',
          style: {
            colors: 'rgba(0,0,0,.4)',
            fontFamily: 'monospace',
          },
          formatter: (value) => {
            let text = '';
            const amount = 6 - value.toFixed(0).length;
            for (let i = 0; i < amount; i++) {
              text += ' ';
            }
            if (value >= 10000) {
              text += `${(value / 1000)} mil`;
            } else {
              text += ` ${value}`;
            }
            return text;
          }
        },
      }
    };
  }

  get series(): ApexAxisChartSeries {
    return [
      {
        name: 'Vendas Online',
        data: (this.data?.parceiros ?? []).map(item => {
          return {
            x: new Date(`${item.ano}-${item.mes}-${item.dia}`).getTime(),
            y: item.quantidade,
          }
        }),
      },
      {
        name: 'APP Consultor',
        data: (this.data?.consultores ?? []).map(item => {
          return {
            x: new Date(`${item.ano}-${item.mes}-${item.dia}`).getTime(),
            y: item.quantidade,
          }
        }),
      }
    ];
  }
}


















import {Component, Prop, Vue} from 'vue-property-decorator';
import {ApexDatalabelFormatter, ApexOptions} from "apexcharts";
import ApexPt from 'apexcharts/dist/locales/pt-br.json';

@Component
export default class GraficoVendedor extends Vue {
  @Prop() public readonly data!: VendedorItem[];
  public faturaIndex = 'ano';
  public colors = [
    '#1e88e5', '#00acc1', '#059669',
    '#cddc39', '#fdd835', '#ff9800',
    '#f44336', '#e91e63', '#8e24aa',
    '#512da8', '#757575',
  ];

  get height(): number {
    return ((this.data ?? []).length * 40) + 50;
  }

  get quantidadeData(): VendedorItem[] {
    return JSON.parse(JSON.stringify(this.data ?? []))
      .sort((a: VendedorItem, b: VendedorItem) => b.quantidade - a.quantidade);
  }

  get tooltipFormat(): string {
    switch (this.faturaIndex) {
      case 'ano': return 'MMMM [de] YYYY';
      default: return 'dddd, DD [de] MMMM [de] YYYY';
    }
  }

  get axisFormat(): string {
    switch (this.faturaIndex) {
      case 'ano': return 'MMM/YY';
      default: return 'DD/MMM';
    }
  }

  get options(): ApexOptions {
    return {
      chart: {
        locales: [ApexPt],
        defaultLocale: 'pt-br',
        fontFamily: '',
        offsetY: 0,
        toolbar: {
          show: false,
        },
      },
      colors: this.colors,
      dataLabels: {
        offsetX: 0,
        offsetY: 16,
        textAnchor: 'start',
        formatter: (val: number, opt: ApexDatalabelFormatter) => {
          return opt.w.globals.labels[opt.dataPointIndex] + ": " + val
        },
        style: {
          colors: ['#222']
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: {
          top: 0,
          left: 0
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '30px',
          dataLabels: {
            position: 'bottom',
          },
        }
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: this.labels,
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false
        }
      },
    };
  }

  get series(): { data: number[] }[] {
    return [{
      data: (this.quantidadeData ?? []).map(item => {
        return item.quantidade;
      })
    }];
  }

  get labels(): string[] {
    return (this.quantidadeData ?? []).map(item => {
      return item.nome;
    });
  }

}

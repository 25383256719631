import Vue from 'vue';

import './components/v-calendar';

import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2, {
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

import { InlineSvgPlugin } from 'vue-inline-svg';
Vue.use(InlineSvgPlugin);

import vueScroll from 'vuescroll';
Vue.use(vueScroll, {
  ops: {},
});

import VuePageTransition from 'vue-page-transition';
Vue.use(VuePageTransition);

import VueMask from 'v-mask';
Vue.use(VueMask);
import money from 'v-money';
Vue.use(money, {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 2,
});

import vueFilePond from 'vue-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
Vue.component('file-pond', FilePond);

import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import VueContext from 'vue-context';
Vue.component('vue-context', VueContext);

import RadialProgressBar from 'vue-radial-progress';
Vue.component('radial-progress-bar', RadialProgressBar);

import Lightbox from 'vue-easy-lightbox';
Vue.use(Lightbox);

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts);

import dayjs from "dayjs";
import './dayjs/pt-br';
dayjs.locale('pt-br');
Vue.prototype.$date = dayjs;















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Comissoes } from '@/plugins/api';
import { columnsModal } from './tabela';
import RecebimentoAdesao from './RecebimentoAdesao.vue';

@Component({
  components: {
    RecebimentoAdesao,
  },
})
export default class ConfirmarAdesao extends Vue {
  @Prop() public readonly data!: number;
  @Prop() public readonly faturas!: IFatura[];

  public processing = false;
  public erro = false;

  public columns = columnsModal;

  get faturaSituacao(): string {
    if ((this.faturas ?? []).length === 0) {
      return '';
    }
    const situacao = this.faturas.some((fatura: IFatura) => {
      return fatura.situacao === 'paid';
    });

    return situacao ? 'paid' : 'pending';
  }

  confirmar(situacao: string): void {
    this.processing = true;
    const data: PatchComissaoRequest = {
      situacao: situacao,
      todos: true,
    };
    Comissoes.patch(this.data, data)
      .then((result) => {
        this.emitChange(result.success);
      })
      .catch(() => {
        this.emitChange(false);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  emitChange(value: boolean): void {
    this.$emit('change', value);
  }
}





























































































import {Component, Vue} from 'vue-property-decorator';
import {Dashboard} from "@/plugins/api";
import GraficoFatura from "./components/GraficoFatura.vue";
import GraficoBoleto from "./components/GraficoBoleto.vue";
import GraficoCadastro from "./components/GraficoCadastro.vue";
import GraficoComissao from "./components/GraficoComissao.vue";
import GraficoVendedor from "@/views/app/Home/components/GraficoVendedor.vue";
import GraficoVendedorQuantidade from "@/views/app/Home/components/GraficoVendedorQuantidade.vue";


@Component({
  components: {
    GraficoFatura,
    GraficoBoleto,
    GraficoCadastro,
    GraficoComissao,
    GraficoVendedor,
    GraficoVendedorQuantidade,
  },
})
export default class HomeIndex extends Vue {
  public loading = true;
  public periodo = {
    start: this.$date().startOf('month').toDate() as Date,
    end: this.$date().toDate() as Date,
  };
  public interval = 'day';

  public faturas = {} as FaturaData;
  public comissoes = {} as ComissaoData;
  public cadastros = {} as CadastroData;
  public vendedores = [] as VendedorItem[];

  public options = [
    {text: 'Dia', value: 'day'},
    {text: 'Mês', value: 'month'},
  ];

  public currentFilter = {
    interval: this.interval,
    start: null,
    end: null,
  } as {[key: string]: string|null};

  mounted(): void {
    this.filter();
  }

  filter(): void {
    this.currentFilter = {
      interval: this.interval,
      start: this.$date(this.periodo.start).format('YYYY-MM-DD'),
      end: this.$date(this.periodo.end).format('YYYY-MM-DD'),
    };
    Dashboard.get({params: this.currentFilter,}).then(
      (response: GetDashboardResponse) => {
        this.loading = false;
        this.faturas = response.data.faturas;
        this.comissoes = response.data.comissoes;
        this.cadastros = response.data.cadastros;
        this.vendedores = response.data.vendedores;
      }
    );
  }

}

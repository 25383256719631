








































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';

@Component({
  name: 'SidebarMenuItem',
})
export default class SidebarMenuItem extends Vue {
  @Prop() private data!: RouteConfig;
  public open = false;

  get sidebarState(): boolean {
    return this.$store.state.sidebar.open;
  }

  get active(): boolean {
    return this.$route.path.startsWith(this.route);
  }

  get name(): string {
    return this.data.name ?? 'menu';
  }

  get title(): string {
    return this.data.meta.title;
  }

  get route(): string {
    return this.data.path;
  }

  get icon(): string {
    return this.data.meta.sidebar.icon;
  }

  get children(): RouteConfig[] | undefined {
    return this.data.children;
  }

  get counter(): number {
    return this.$store.state.sidebar.badges[this.route] ?? 0;
  }

  public onClick(navigate: () => void): void {
    this.$store.commit('SET_SIDEBAR_OPEN', false);
    this.open = false;
    navigate();
  }

  public onOpen(): void {
    this.$store.commit('SET_SIDEBAR_OPEN', true);
  }

  @Watch('sidebarState')
  updateSidebar(isOpen: boolean): void {
    this.open = isOpen ? this.open : false;
  }
}

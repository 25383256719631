
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FormatDate extends Vue {
  @Prop() public readonly data!: number | string;
  @Prop({ default: 'timestamp' }) public readonly input!: string;
  @Prop({ default: 'DD/MM/YYYY' }) public readonly output!: string;
  @Prop({ default: '-' }) public readonly text!: string;

  get timestamp(): number | Date {
    if (this.input == 'datetime') {
      return new Date(this.data as string);
    }
    return parseInt(this.data as string);
  }
}

import Api from './Api';

export default class Dashboard {
  public static async get(request?: IRequestOptions): Promise<GetDashboardResponse> {
    const response = await Api.instance.get<GetDashboardResponse>('/dashboard', {
      params: request?.params,
    });
    return response.data;
  }
}

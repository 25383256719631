






































































































































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { Comissoes } from '@/plugins/api';
import { BTable, BvTableCtxObject } from 'bootstrap-vue/src/components/table';
import { columnsRecebimentos } from './tabela';

@Component
export default class RecebimentoAdesao extends Vue {
  @Ref() public readonly table!: BTable;
  @Prop() public readonly data!: number;

  public columns = columnsRecebimentos;
  public loading = true;
  public processing = false;
  public erro = false;
  public totalRows = 0;

  public dados = { data_recebimento: this.hoje() } as IRecebimento;
  public metodos = ['dinheiro', 'cartao', 'cheque', 'boleto', 'pix', 'transferencia'];

  public dateFormat = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  public locale = {
    labelPrevDecade: 'Década Anterior',
    labelPrevYear: 'Ano Anterior',
    labelPrevMonth: 'Mês Anterior',
    labelCurrentMonth: 'Mês Atual',
    labelNextMonth: 'Próximo Mês',
    labelNextYear: 'Próximo Ano',
    labelNextDecade: 'Próxima Década',
    labelToday: 'Hoje',
    labelSelected: 'Selecionado',
    labelNoDateSelected: 'Nenhuma data selecionada',
    labelCalendar: 'Calendário',
    labelNav: 'Navegar',
    labelHelp: 'Use as setinhas para navegar pelas datas',
  };

  provider(
    context: BvTableCtxObject,
    callback: (data: IRecebimento[]) => void
  ): void {
    this.loading = true;

    const request: IRequestOptions = {
      params: {},
    };

    Comissoes.getRecebimentos(this.data, request)
      .then((response: GetRecebimentosResponse) => {
        this.totalRows = response.metadata.total;
        callback(response.data);
      })
      .catch(() => {
        callback([]);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  hoje(): string {
    return new Date().toLocaleDateString('en-CA');
  }

  resetForm(): void {
    this.dados = { data_recebimento: this.hoje() } as IRecebimento;
  }

  salvar(): void {
    this.processing = true;
    Comissoes.postRecebimentos(this.data, this.dados)
      .then(() => {
        this.resetForm();
      })
      .finally(() => {
        this.processing = false;
        this.table?.refresh();
      });
  }
  apagar(id: number): void {
    this.processing = true;
    Comissoes.deleteRecebimentos(this.data, id)
      .then(() => {
        return;
      })
      .finally(() => {
        this.processing = false;
        this.table?.refresh();
      });
  }

  confirmar(situacao: string): void {
    this.processing = true;
    const data: PatchComissaoRequest = {
      situacao: situacao,
      todos: true,
    };
    Comissoes.patch(this.data, data)
      .then((result) => {
        this.$emit('change', result.success);
      })
      .catch(() => {
        this.$emit('change', false);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }

  labelMetodo(value: string): string {
    switch (value) {
      case 'cartao':
        return 'Cartão';
      case 'boleto':
        return 'Boleto';
      case 'cheque':
        return 'Cheque';
      case 'dinheiro':
        return 'Dinheiro';
      case 'pix':
        return 'PIX';
      case 'transferencia':
        return 'Transferência';
    }
    return '';
  }
}
